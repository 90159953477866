import * as React from "react";
import Layout from "../../components/layout";

// markup
const StaffPage = () => {
  return (
    <Layout
      title="スタッフ | CO Record &amp; journal"
      policyStyle={false}
      showHeader={true}
      isTop={false}
    >
      <main className="pageStyles">
        <div>
          <div className="staff-image-parent">
            <img
              className="staff-image"
              src="../images/logo_red.png"
              alt="co_logo"
            />
          </div>

          <p className="staff-head">Staffs</p>

          <p className="staff-text">MASUMURA SEIJI / Exective Producer</p>
          <p className="staff-text">NIKAIDO HIKARU / Producer</p>
          <p className="staff-text">OGINO HIROMI / Producer</p>
          <p className="staff-text">BITO HIROKI / Director</p>
          <p className="staff-text">
            SHIHONMATSU KAZUHISA / Project Manager / iOS Engineer
          </p>
          <p className="staff-text">SAWADA NORIHIRO / Lead Planner</p>
          <p className="staff-text">MORI RIKA / Android Engineer</p>
          <p className="staff-text">NGUYEN VAN HOA / Infrastructure Engineer</p>
          <p className="staff-text">YAMADERA RINA / Planner</p>
          <p className="staff-text">YOMODA NANAHO / UI Designer</p>
          <br />
          <p className="staff-text">Crico,Inc.</p>
          <p className="staff-text">
            WAKUI GENKI / Project Manager / Art Director
          </p>
          <p className="staff-text">WANG MEIXIN / Illustrator</p>
          <br />
          <p className="staff-text">STUDIO・LEMON</p>
          <p className="staff-text">KIMURA TAKU / Animation Director</p>
          <br />
        </div>
      </main>
    </Layout>
  );
};

export default StaffPage;
